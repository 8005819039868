import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LoadingBar from 'react-top-loading-bar';
import { selectIsShowLoadingBar } from '../state/loadingBarSelectors';

export const LoadingBarComponent = (): JSX.Element => {
  const isLoading = useSelector(selectIsShowLoadingBar);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (isLoading === true) {
      setProgress(1);
    }
    if (isLoading === false) {
      setProgress(100);
    }
  }, [isLoading]);

  useEffect(() => {
    let intervalId: string | number | NodeJS.Timeout | undefined;

    if (progress > 0 && progress < 100) {
      intervalId = setInterval(() => {
        setProgress(prevProgress => {
          const newProgress = prevProgress + 5;
          if (newProgress >= 100) {
            clearInterval(intervalId); // Stop the interval when progress reaches 100
            return 100; // Ensure it's exactly 100
          }
          return newProgress;
        });
      }, 250); // Adjust the interval for speed (lower is faster)
    }

    return () => clearInterval(intervalId); // Cleanup on unmount or re-run
  }, [progress]);

  return <LoadingBar color="#000" progress={progress} />;
};
